import * as React from "react";
import styled from "@emotion/styled";
import { colors, screenSize, quicksandFont } from "../../utils/css/themes";
import { useUrlParse } from "../../utils/useUrlParse";

const ExtNavHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => colors[props.color]};
  border-radius: 4px 4px 0px 0px;
`;

const HeaderText = styled.h2`
  color: white;
  margin: 0;
  padding: 1rem;
`;
const ExtNavLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 16px 24px;
  a {
    font-family: ${quicksandFont.medium};
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    padding-bottom: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${colors.blue};
  }
`;

const ExtNavBoxStyle = styled.div`
  width: 40%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 4px 10px 40px 0 rgba(0, 0, 0, 0.15);
  margin: 24px;

  @media (max-width: ${screenSize.smallTablet}) {
    width: 100%;
  }
`;

const ExtBoxesContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 40px;
  @media (max-width: ${screenSize.smallTablet}) {
    flex-direction: column;
    margin: 1rem auto;
    align-items: center;
  }
`;
const StyledLink = styled.a`
  font-size: 18px;
  font-family: ${quicksandFont.regular};
  color: ${colors.blue};
`;
const isBrowser = typeof window !== `undefined`;

const setStorage = (link: string, title: string) => {
  if (isBrowser) {
    window.localStorage.setItem("link", link);
    window.localStorage.setItem("page", title);
  }
};
function formatLink(link: any, index: number) {
  if (link.linkType === "External Link") {
    if (!link.externalLink.includes("http"))
      link.externalLink = `//${link.externalLink}`;
    return (
      <StyledLink key={index} href={link.externalLink}>
        {link.label}
      </StyledLink>
    );
  }
  if (link.linkType === "Internal Link") {
    return (
      <StyledLink href={useUrlParse(link.internalLink)}>
        {link.label}
      </StyledLink>
    );
  }
  if (link.linkType === "File") {
    var file_url = `${link.fileUpload && link.fileUpload.sourceUrl}`;
    return <StyledLink href={file_url}>{link.label}</StyledLink>;
  }
}

const ResearchHubExtNav = (props: WordpressPost) => {
  return (
    <ExtBoxesContainer>
      {props.issueLinkBoxes.learnMoreLinks &&
        props.issueLinkBoxes.learnMoreLinks[0].label && (
          <ExtNavBoxStyle>
            <ExtNavHeader color={props.issueLinkBoxes.linkColor}>
              <HeaderText>{"Learn More"}</HeaderText>
            </ExtNavHeader>
            <ExtNavLinks>
              {props.issueLinkBoxes.learnMoreLinks &&
                props.issueLinkBoxes.learnMoreLinks.map(
                  (val: any, index: number) => formatLink(val, index)
                )}
            </ExtNavLinks>
          </ExtNavBoxStyle>
        )}

      {props.issueLinkBoxes.relatedNonprofitPages &&
        props.issueLinkBoxes.relatedNonprofitPages[0].label && (
          <ExtNavBoxStyle>
            <ExtNavHeader color={props.issueLinkBoxes.linkColor}>
              <HeaderText>{"Nonprofit Pages"}</HeaderText>
            </ExtNavHeader>
            <ExtNavLinks>
              {setStorage(props.slug, props.title)}
              {console.log(props.issueLinkBoxes.relatedNonprofitPages)}
              {props.issueLinkBoxes.relatedNonprofitPages &&
                props.issueLinkBoxes.relatedNonprofitPages.map(
                  (val: any, index: number) => (
                    <StyledLink
                      key={index}
                      href={useUrlParse(val?.nonprofitPage?.link) || "#"}
                    >
                      {val.label}
                    </StyledLink>
                  )
                )}
            </ExtNavLinks>
          </ExtNavBoxStyle>
        )}
    </ExtBoxesContainer>
  );
};

export default ResearchHubExtNav;

interface WordpressPost {
  issueLinkBoxes: {
    linkColor: string;
    learnMoreLinks: Array<{
      externalLink: string;
      fileUpload: {
        sourceUrl: string;
      };
      internalLink: string;
      label: string;
      linkType: string;
    }>;
    relatedNonprofitPages: Array<{
      label: string;
      nonprofitPage: string;
    }>;
  };
  title: string;
  slug: string;
}
